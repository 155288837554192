import VARS from 'utils/vars.js'
import { saveFile } from 'utils/utils.js'

//const baseUrl = 'https://tag-dev.buniq.it/';
const baseUrl = VARS.baseUrl;

const apiUrl = baseUrl + 'api/';


export const downloadZip = async (token, path, data) => {
  try {
    const res = await fetch(apiUrl + 'v2/' + path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/zip'  
      },
      body: JSON.stringify(data)
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    // Get the filename from the Content-Disposition header, or use a default

    // log all headers
    for (var pair of res.headers.entries()) {
      console.log(pair[0]+ ': '+ pair[1]);
    }

    let contentDisposition = res.headers.get('Content-Disposition');
   
    const filename = contentDisposition
      ? contentDisposition.split('filename=')[1].replace(/['"]/g, '')
      : 'download.zip';  // Default filename with .zip extension

    const resDataBlob = await res.blob();

    // Use the saveFile function to trigger the download
    saveFile(resDataBlob, filename);


    return res.status;

  } catch (error) {
    console.error("Error downloading file:", error);
    throw error;
  }
}



export const download = (token, path, data) => {
	fetch(
		apiUrl + 'v2/' + path, 
		{
			headers: {
				Accept: "application/octet-stream",
				Authorization: "Bearer " + token
			},
		},
	)
	.then((res) => {
		//console.log("RESPONSE", res)
		if (!res.ok) {
			return res.status.toString()
		}
		//return res.body
    let filename = res.headers.get('content-disposition').split('"')[1] //'prova.pdf'
		if(!filename.includes('.pdf')) filename = `${filename}.pdf`;
    //console.log("filename", filename);
    return {body: res.body, filename}
	})
 .then((data) => {
		const {filename, body} = data;
    const reader = body.getReader();
    const stream = new ReadableStream({
      start(controller) {
        return pump();
        function pump() {
          return reader.read().then(({ done, value }) => {
            // When no more data needs to be consumed, close the stream
            if (done) {
              controller.close();
              return;
            }
            // Enqueue the next data chunk into our target stream
            controller.enqueue(value);
            return pump();
          });
        }
      },
    });
		return {filename, stream};
  })
	.then(response => {
		const {filename, stream} = response
		new Response(stream).blob().then(
			blob => saveFile(blob, filename)
		);
	})
  /*.then((blob) => {
		//const myBlob = new Blob([blob], {type: 'application/pdf'});
		//window.open(URL.createObjectURL(myBlob))
		return saveFile(blob, 'filename.pdf')
	})*/
	.catch((err) => {
			return err.Message;
	})

}

export const redirect = (token, path) => {
		const headers = new Headers();
		headers.append('Accept', '*');
		headers.append('Authorization', 'Bearer ' + token);
		headers.append("Access-Control-Allow-Origin", '*');
    headers.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Authorization');
		return fetch(path, {
	    method: 'GET',
  	  headers,
			rejectUnauthorized: false,
  	})
	  .then(response => {
			console.log("response.type =", response.type);
			return response.text().then(data => data);

			response.blob().then(blob => {
				return blob;
				if(blob){
					const url = URL.createObjectURL(blob);
					return {url: url}
				}else{
					return {error: 'Something went wrong!'}
				}
 			})
		});
}

export const login = (username, password) => {
  const data = new FormData();
  data.append('email', username);
  data.append('password', password);
  
  return fetch(apiUrl + "login", {
    method: 'POST',
    headers: {'Accept': 'application/json', "Access-Control-Allow-Origin": '*'},
    body: data,
  }).then(res => {
    const dataName = res.ok ? 'data' : 'error';
    return res.json().then(data => ({
      authenticated: res.ok ? true : false,
      [dataName]: res.ok ? {...data, user: username} : data.error
    }));
  });
}

export const send_reset_password_link = (username) => {
  const data = new FormData();
  data.append('email', username);

  return fetch(apiUrl + "password/forgot", {
    method: 'POST',
    headers: {'Accept': 'application/json', "Access-Control-Allow-Origin": '*'},
    body: data,
  }).then(res => {
    const dataName = res.ok ? 'data' : 'error'; 

    return res.json().then(data => ({
      done: res.ok ? true : false,
      [dataName]: res.ok ? {...data, user: username} : data.error
    }));
  }).catch(error => console.log(error));

}


export const send_new_password = (username, password, token) => {
  const data = new FormData();
  data.append('email', username);
  data.append('password', password);
  data.append('password_confirmation', password);
  data.append('token', token);
	data.append('_method', 'PUT');

  return fetch(apiUrl + "password/reset", {
    method: 'POST',
    headers: {'Accept': 'application/json', "Access-Control-Allow-Origin": '*'},
    body: data,
  }).then(res => {
    const dataName = res.ok ? 'data' : 'error';

    return res.json().then(data => ({
      done: res.ok ? true : false,
      [dataName]: res.ok ? {...data, user: username} : {error: data.errors}
    }));
  }).catch(error => console.log(error));

}

export const api = (token, method, path, data = null) => {
  return fetch(apiUrl + 'v2/' + path, {
    method: method,
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    body: data,
  }).then(res => {
    const dataName = res.ok ? 'data' : 'error';
    return res.json().then(data => {
			//console.log(data);
			let out = {
      	[dataName]: res.ok ? {...data} : (data.error ?? data.errors)
    	};
			//console.log(out);
			return out;
		});
  }).catch(err => {
    console.log(err)
		return err;
	});
}
